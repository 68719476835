import { ApiResponse } from '../../types/ApiType';
import { BusArrival } from '../../types/BusArrivalType';
import dataportalInstance from '../axios/dataportalInstance';

/**
 * 해당 정류소에 정차하는 모든 노선에 대한 첫번째/두번째 도착예정 버스의 위치정보와 도착예정시간, 빈자리, 저상버스 정보를 제공하는 버스도착정보목록서비스
 */
export const getBusArrivalList = async (stationId: string) => {
	const response = await dataportalInstance.get('/6410000/busarrivalservice/getBusArrivalList', {
		params: {
			stationId,
		},
	});
	const data: ApiResponse<BusArrival> = response.data.response;
	console.log(data);
	if (data.msgHeader[0].resultCode && data.msgHeader[0].resultCode[0] === '0') {
		console.log(data);
		return data.msgBody[0].busArrivalList;
	}
	console.log(`busArrivalList: ${data.msgHeader[0].resultMessage}`);
	return null;
};
/**
 * 해당 정류소(정류소ID)에 정차하는 특정 노선(노선ID)의 도착 정보를 조회한다
 */
export const getBusArrivalItem = async (stationId: string, routeId: string, staOrder?: string) => {
	const response = await dataportalInstance.get('/6410000/busarrivalservice/getBusArrivalItem', {
		params: {
			stationId,
			routeId,
			staOrder,
		},
	});
	const data: ApiResponse<BusArrival> = response.data.response;
	console.log(data);
	if (data.msgHeader[0].resultCode && data.msgHeader[0].resultCode[0] === '0') {
		console.log(data);
		return data.msgBody[0].busArrivalItem[0];
	}
	console.log(`busArrivalItem: ${data.msgHeader[0].resultMessage}`);
	return null;
};
