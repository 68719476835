import React, {
	useEffect,
	// useState
} from 'react';
// import * as busRoute from '../services/api/busRoute';
// import * as busArrival from '../services/api/busArrival';
// import { BusRouteInfoItem, BusRouteList, BusStationList } from '../types/BusRouteType';
// import BasicCard from '../components/molecules/BasicCard';
// import { BusArrival, BusArrivalList } from '../types/BusArrivalType';
import Dashboard from '../components/templates/Dashboard';

function HomePage() {
	// const [busRouteInfoData, setBusRouteInfoData] = useState<BusRouteInfoItem | null>(null);
	// const [busRouteList, setBusRouteList] = useState<BusRouteList | null>(null);
	// const [busRouteStationList, setBusRouteStationList] = useState<BusStationList | null>(null);
	// const [busArrivalList, setBusArrivalList] = useState<BusArrivalList | null>(null);
	// const [busArrivalItem, setBusArrivalItem] = useState<BusArrival | null>(null);

	useEffect(() => {
		// // 노선 ID로 버스 노선 정보 검색
		// busRoute.getBusRouteInfoItem('200000085').then((res) => {
		// 	if (res) {
		// 		console.log('BusRouteInfoItem');
		// 		console.log(res);
		// 		setBusRouteInfoData(res);
		// 	}
		// });
		// // 버스 번호로 노선 ID 검색
		// busRoute.getBusRouteList('7770').then((res) => {
		// 	if (res) {
		// 		console.log('getBusRouteList');
		// 		console.log(res);
		// 		setBusRouteList(res);
		// 	}
		// 	// console.log(res.response.msgBody[0].busRouteList[2].routeId[0]);
		// });
		// // 노선 ID로 정류장 ID 검색
		// busRoute.getBusRouteStationList('234000044').then((res) => {
		// 	if (res) {
		// 		console.log('getBusRouteStationList');
		// 		console.log(res);
		// 		setBusRouteStationList(res);
		// 	}
		// 	// console.log(res.response.msgBody[0].busRouteStationList[70].stationName[0]);
		// 	// console.log(res.response.msgBody[0].busRouteStationList[70].stationId[0]);
		// });
		// // 정류장 ID로 버스도착정보 검색
		// busArrival.getBusArrivalList('200000078').then((res) => {
		// 	console.log('getBusArrivalList');
		// 	console.log(res);
		// 	setBusArrivalList(res);
		// 	// console.log(res.response.msgBody[0].busRouteStationList[70].stationName[0]);
		// 	// console.log(res.response.msgBody[0].busRouteStationList[70].stationId[0]);
		// });
	}, []);

	// if (!busRouteInfoData || !busRouteList || !busRouteStationList || !busArrivalList || !busArrivalItem) {
	// 	return <div>Loading...</div>;
	// }

	return <Dashboard />;
}

export default HomePage;
