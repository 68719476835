import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { grey, teal } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { BusRouteInfoItem } from '../../types/BusRouteType';
import { BusArrival } from '../../types/BusArrivalType';
import { getBusRouteInfoItem } from '../../services/api/busRoute';
import { getBusArrivalItem } from '../../services/api/busArrival';

export interface BusArrivalCardParams {
	stationName: string;
	mobileNo: string;
	routeId: string;
	stationId: string;
}

export default function BusArrivalCard({ stationName, mobileNo, routeId, stationId }: BusArrivalCardParams) {
	const [busArrivalItem, setBusArrivalItem] = useState<BusArrival | null>(null);
	const [busRouteInfoData, setBusRouteInfoData] = useState<BusRouteInfoItem | null>(null);

	useEffect(() => {
		// 노선 ID로 버스 노선 정보 검색
		getBusRouteInfoItem(routeId).then((res) => {
			if (res) {
				console.log('BusRouteInfoItem');
				console.log(res);
				setBusRouteInfoData(res);
			}
		});
		getBusArrivalItem(stationId, routeId).then((res) => {
			console.log('getBusArrivalItem');
			console.log(res);
			setBusArrivalItem(res);
		});
	}, [routeId, stationId]);

	const loadingText = 'Loading...';

	return (
		<Card sx={{ minWidth: 275 }}>
			<CardHeader
				title={stationName}
				subheader={mobileNo}
				titleTypographyProps={{ fontSize: '16px', fontWeight: '600', color: grey[100] }}
				subheaderTypographyProps={{ fontSize: '14px', color: grey[300] }}
				style={{ backgroundColor: teal[200] }}
			/>
			<CardContent>
				<Typography variant="h5" component="div">
					{busRouteInfoData ? busRouteInfoData.routeName : loadingText}
				</Typography>
				<Typography sx={{ fontSize: 12 }} color="text.secondary">
					{busRouteInfoData ? busRouteInfoData.routeTypeName : loadingText}
				</Typography>
				<Typography sx={{ mb: 1.5, fontSize: 10 }} color="text.secondary" gutterBottom>
					{busRouteInfoData ? busRouteInfoData.regionName : loadingText}
				</Typography>
				<Grid container direction="row" alignItems="center">
					{busArrivalItem ? (
						<>
							<Typography sx={{ fontSize: 16, mr: 1 }} variant="body2">
								{busArrivalItem ? `${busArrivalItem.predictTime1}분 남음` : loadingText}
							</Typography>
							{busArrivalItem.remainSeatCnt1[0] !== '-1' && (
								<Chip
									label={`${busArrivalItem.remainSeatCnt1}석`}
									size="small"
									sx={{
										bgcolor: teal[300],
										height: '20px',
										width: '42px',
										fontSize: '12px',
										color: grey[200],
									}}
								/>
							)}

							{/* <Box bgcolor={teal[300]} borderRadius={10} textAlign="center">
								<Typography sx={{ fontSize: 10 }} color={grey[300]}>
									{busArrivalItem ? `${busArrivalItem.remainSeatCnt1}석` : loadingText}
								</Typography>
							</Box> */}
						</>
					) : (
						<Typography sx={{ fontSize: 16 }} variant="body2">
							정보 없음
						</Typography>
					)}
				</Grid>
			</CardContent>
			{/* <CardActions>
				<Button size="small">상세 보기</Button>
			</CardActions> */}
		</Card>
	);
}
