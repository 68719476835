import { ApiResponse } from '../../types/ApiType';
import { BusRouteInfoItem, BusRouteType, BusStation } from '../../types/BusRouteType';
import dataportalInstance from '../axios/dataportalInstance';

/**
 * 노선ID에 해당하는 노선의 기본 정보 및 배차 정보를 조회한다
 * 
 * @returns 
 * 
    {
        "companyId": ["4103100"],
        "companyName": ["수원여객"],
        "companyTel": ["031-244-5341"],
        "districtCd": ["2"],
        "downFirstTime": ["06:20"],
        "downLastTime": ["23:35"],
        "endMobileNo": ["37707"],
        "endStationId": ["233001703"],
        "endStationName": ["한화꿈에그린"],
        "peekAlloc": ["11"],
        "regionName": ["수원,화성"],
        "routeId": ["200000085"],
        "routeName": ["98"],
        "routeTypeCd": ["13"],
        "routeTypeName": ["일반형시내버스"],
        "startMobileNo": ["1070"],
        "startStationId": ["200000165"],
        "startStationName": ["이목동차고지.이목동입구"],
        "upFirstTime": ["04:50"],
        "upLastTime": ["22:10"],
        "nPeekAlloc": ["15"]
    }
 */
export const getBusRouteInfoItem = async (routeId: string) => {
	/**
	 * response 형태
	 * {
			"comMsgHeader": [""],
			"msgHeader": [{	
				"queryTime": ["2023-06-28 01:30:41.490"],
				"resultCode": ["4"],
				"resultMessage": ["결과가 존재하지 않습니다."]
			}]
		}
	 */
	const response = await dataportalInstance.get('/6410000/busrouteservice/getBusRouteInfoItem', {
		params: {
			routeId,
		},
	});
	const data: ApiResponse<BusRouteInfoItem> = response.data.response;
	if (data.msgHeader[0].resultCode[0] === '0') {
		return data.msgBody[0].busRouteInfoItem[0];
	}

	console.log(data.msgHeader[0].resultMessage);
	return null;
};
/**
 * 노선ID에 해당하는 노선의 경유 정류소 목록을 조회한다
 */
export const getBusRouteStationList = async (routeId: string) => {
	const response = await dataportalInstance.get('/6410000/busrouteservice/getBusRouteStationList', {
		params: {
			routeId,
		},
	});
	const data: ApiResponse<BusStation> = response.data.response;
	if (data.msgHeader[0].resultCode[0] === '0') {
		return data.msgBody[0].busRouteStationList;
	}

	console.log(data.msgHeader[0].resultMessage);
	return response.data;
};
/**
 * 노선번호에 해당하는 노선의 목록을 조회한다
 */
export const getBusRouteList = async (keyword: string) => {
	const response = await dataportalInstance.get('/6410000/busrouteservice/getBusRouteList', {
		params: {
			keyword,
		},
	});
	const data: ApiResponse<BusRouteType> = response.data.response;
	if (data.msgHeader[0].resultCode[0] === '0') {
		return data.msgBody[0].busRouteList;
	}

	console.log(data.msgHeader[0].resultMessage);
	return null;
};
/**
 * 노선번호에 해당하는 노선의 목록을 조회한다
 */
export const getAreaBusRouteList = async (areaId: string, keyword: string) => {
	const response = await dataportalInstance.get('/6410000/busrouteservice/getAreaBusRouteList', {
		params: {
			areaId,
			keyword,
		},
	});
	return response.data;
};
/**
 * 노선ID에 해당하는 노선의 형상정보를 조회한다.
 */
export const getBusRouteLineList = async (routeId: string) => {
	const response = await dataportalInstance.get('/6410000/busrouteservice/getBusRouteLineList', {
		params: {
			routeId,
		},
	});
	return response.data;
};
